import accountServices from '../../../services/account'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import Vue from "vue";
import VueCountryCode from "vue-country-code-select";

Vue.use(VueCountryCode);
export default {
    data () {
      return {    
        firstname: '',
        lastname: '',
        title: '',
        companyname: '',
        email: '',
        phoneno: '',
        dialcode: '',
        terms: true,
        isCheckAll: false,
      }
    },
    methods: {
    DisableChange (event) {
      debugger
      if(this.terms)
      {
              this.terms = false
      }
      else 
      {
          this.terms = true
      }
    return !this.terms;
    },
      onSelect({name, iso2, dialCode}) {
        this.dialCode = dialCode;
      },
      OpenUrl(url) {
        this.openExternalLink(url)
    },
      async onSubmitLogin(){
        const isValid = await this.$refs['observer'].validate()
        return Auth.signUp({
          username: this.email,
          password: 'Temp@321',
          attributes: {
            email: this.email,
            'custom:firstname': this.firstname,
            'custom:lastname': this.lastname,
            'custom:title': this.title,
            'custom:companyname': this.companyname,
            'custom:UrlLink': 'https://erqa.notisphererecalls.com/verify-email?e=' + this.email,
            phone_number: (this.phoneno != "" && this.phoneno != "" ? "+"  + this.dialCode + this.phoneno:null ),
          },
        })
          .then(data => {
            AmplifyEventBus.$emit('localUser', data.user);
            if (data.userConfirmed === false) {
              AmplifyEventBus.$emit('authState', 'confirmSignUp');
              this.$router.push({name: 'SuccessPage', params: {
                title: 'Sign up successfully!',
                subTitle: 'Kindly check your email for further action',
                icon:'far fa-check-circle'
              }})
            } else {
              AmplifyEventBus.$emit('authState', 'signIn');
            }
            return data;
          })
          .catch(err => {
            debugger
            return Auth.signIn(this.email.toLowerCase(), '123').then(res => {
              return false;
          }).catch(error => {
            debugger
           const code = error.code;
           //console.log(error);
           if(code != undefined)
           {
            switch (code) {
              case 'UserNotFoundException':
                  return this._showToast(err.message, { variant: 'warning' })
              case 'NotAuthorizedException':
                  return this._showToast(err.message, { variant: 'warning' })
              case 'PasswordResetRequiredException':
                return this._showToast('Account Verification is Pending', { variant: 'warning' })
              case 'UserNotConfirmedException':
                  return this._showToast('Account Verification is Pending', { variant: 'warning' })
              default:
                  return this._showToast(err.message, { variant: 'warning' })
          }
           }
           else
           {
            if(this.firstname != "" && this.lastname != "" && this.title != "" && this.companyname != "" && this.email != "")
            {
              this._showToast(err.message, { variant: 'warning' })
            }
           }
           
         });
          });
      },
    }
  }